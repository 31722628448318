<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Article</CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="title"
                      label="Title"
                      placeholder="Enter the title"
                      v-model="article.title"
                      required
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      name="excerpt"
                      label="excerpt"
                      placeholder="Enter the excerpt"
                      v-model="article.excerpt"
                      required
                    />
                  </CCol>

                  <CCol sm="6">
                    <CInputFile
                      name="image"
                      label="Image"
                      description="Maximum upload size is 1MB.
                          Images can be compressed using imagecompressor.com"
                      v-on:change="setImage"
                      required
                    />
                  </CCol>

                  <CCol sm="12">
                    <ckeditor
                      :editor="editor"
                      v-model="article.content"
                      :config="editorConfig"
                    ></ckeditor>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="createArticle"
            >
              <CIcon name="cil-check-circle" />
              Create
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ArticleAPI from "/app/src/api/article.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Create",
  data: () => {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
      article: {
        title: "",
        excerpt: "",
        content: "",
      },
      image: {},
      articleAPI: new ArticleAPI(),
    };
  },
  created: function () {},
  methods: {
    validateSubmission: function () {
      if (this.article.title.length < 1) return "Name is required";
      if (this.article.excerpt.length < 1) return "Excerpt is required";
      if (this.article.content.length < 1) return "Content is required";
      if (this.image && this.image.size > 1200000)
        return "Image is too large. Max. 1MB";
      return true;
    },
    createArticle: function () {
      let message = this.validateSubmission();
      if (message !== true) {
        this.$store.dispatch("stopLoading");
        this.$alert.show({ type: "danger", message: message });
        return;
      }
      this.$store.dispatch("loading");
      let self = this;
      this.articleAPI
        .create(self.article, this.image)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          self.$router.push({ path: `/manager/articles/${result.id}` });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    setImage: function (files, e) {
      this.image = files[0];
      console.log(this.image);
    },
  },
};
</script>
